import React from "react";
import ReactDOM from "react-dom/client";

// third party
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// project imports
import * as serviceWorker from "serviceWorker";
import App from "App";
import { store, persister } from "store";

// style + assets
import "assets/scss/style.scss";
import config from "config";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persister}>
			<BrowserRouter basename={config.basename}>
				<App />
			</BrowserRouter>
		</PersistGate>
	</Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
